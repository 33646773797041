import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_f = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - F'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>F</h2>
     <LatQuotesIntro />
    
     <p><b>Faciendum</b> - Something which is to be done.</p>
<p><b>Factum</b> - An act or deed.</p>
<p><b>Facultas probationum non est angustanda</b> - The right of offering proof is not to be narrowed.</p>
<p><b>Falsa demonstratio non nocet</b> - A false description does not vitiate.</p>
<p><b>Fatetur facinus qui judicium fugit</b> - He who flees judgment confesses his guilt.</p>
<p><b>Felix qui potuit rerum cognoscere causas </b> - Happy is he who has been able to understand the causes of things.</p>
<p><b>Felonia implicatur in qualibet proditione</b> - Felony is implied in every treason.</p>
<p><b>Festinatio justitiae est noverca infortunii</b> - The hurrying of justice is the stepmother of misfortune.</p>
<p><b>Fictio cedit veritati; fictio juris non est, ubi veritas</b> - Fiction yields to truth. Where truth is, fiction of law does not exist.</p>
<p><b>Fides servanda est</b> - Good faith is to be preserved.</p>
<p><b>Fieri facias (abreviated fi. fa.)</b> -  That you cause to be made.</p>
<p><b>Filiatio non potest probari</b> - Filiation cannot be proved.</p>
<p><b>Firmior et potentior est operatio legis quam dispositio hominis</b> - The operation of law is firmer and more powerful than the will of man.</p>
<p><b>Forma legalis forma essentialis est</b> - Legal form is essential form.</p>
<p><b>Fortior est custodia legis quam hominis</b> - The custody of the law is stronger than that of man.</p>
<p><b>Fractionem diei non recipit lex</b> - The law does not regard a fraction of a day.</p>
<p><b>Fraus est celare fraudem</b> - It is a fraud to conceal a fraud.</p>
<p><b>Fraus est odiosa et non praesumenda</b> - Fraud is odious and is not to be presumed.</p>
<p><b>Fraus et jus nunquam cohabitant</b> - Fraud and justice never dwell together.</p>
<p><b>Fructus naturales</b> - Vegetation which grows naturally without cultivation.</p>
<p><b>Frustra probatur quod probatum non relevat</b> - That is proved in vain which when proved is not relevant.</p>
<p><b>Furor contrahi matrimonium non sinit, quia consensus opus est</b> - Insanity prevents marriage from being contracted because consent is needed.</p>


   </Layout>
  )
}

export default LatQuotes_f
